import '@aws-amplify/ui-react/styles.css';

import React, { useEffect } from 'react';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { Flex, Box } from '@chakra-ui/react';
import { LoadingPage, Main, theme } from 'koble-ui-lib';
import {
  Authenticator,
  useAuthenticator,
  ThemeProvider
} from '@aws-amplify/ui-react';

const Index: NextPage = () => {
  const router = useRouter();
  const { authStatus, isPending } = useAuthenticator((context) => [
    context.authStatus,
    context.isPending
  ]);

  useEffect(() => {
    if (!isPending && authStatus === 'authenticated') {
      const redirectUrl = router.query?.onLoginUrl as string;
      router.replace(redirectUrl ?? '/');
    }
  }, [authStatus, isPending]);

  const email = Array.isArray(router.query?.email)
    ? router.query.email[0]
    : router.query?.email;
  const password = Array.isArray(router.query?.password)
    ? router.query?.password[0]
    : router.query?.password;

  const formFields = {
    signUp: {
      email: {
        order: 3,
        isRequired: true,
        defaultValue: email
      },
      family_name: {
        order: 2,
        isRequired: true,
        placeholder: 'Surname'
      },
      given_name: {
        order: 1,
        isRequired: true,
        placeholder: 'Name'
      },
      password: {
        order: 5,
        isRequired: true,
        defaultValue: password
      },
      confirm_password: {
        order: 6,
        isRequired: true
      }
    },
    signIn: {
      username: {
        order: 1,
        isRequired: true,
        defaultValue: email
      },
      password: {
        order: 2,
        isRequired: true,
        defaultValue: password
      }
    }
  };

  return (
    <Main>
      <Flex justifyContent="center" alignItems="center" height="100%">
        <Box
          maxW={['auto', 'auto', '95%']}
          __css={{
            '.amplify-button--primary': {
              borderRadius: theme.components.Button.baseStyle.borderRadius
            },
            '.amplify-button--small': {
              borderRadius: 'sm'
            },
            '.amplify-label': {
              fontWeight: theme.fontWeights.semibold
            }
          }}
        >
          <ThemeProvider
            theme={{
              name: 'Auth',
              tokens: {
                fonts: {
                  default: {
                    variable: { value: theme.fonts.body },
                    static: { value: theme.fonts.mono }
                  }
                },
                colors: {
                  brand: theme.colors.primary,
                  border: theme.colors.border
                },
                components: {
                  authenticator: {
                    router: {
                      boxShadow: 'none',
                      borderWidth: '0px'
                    }
                  },
                  button: {
                    _loading: {
                      color: 'white'
                    },
                    primary: {
                      color: 'white',
                      backgroundColor: theme.colors.primary,
                      _hover: {
                        backgroundColor: theme.colors.purple[300]
                      },
                      _active: {
                        backgroundColor: theme.colors.purple[300]
                      },
                      _loading: {
                        backgroundColor: theme.colors.purple[300],
                        color: 'white'
                      },
                      _focus: {
                        borderColor: theme.colors.primary,
                        backgroundColor: theme.colors.purple[300]
                      }
                    }
                  },
                  fieldcontrol: {
                    borderRadius: 'none',
                    borderColor: theme.colors.black[900],
                    _focus: {
                      boxShadow: 'none'
                    }
                  }
                }
              }
            }}
          >
            {router.isReady ? (
              <Authenticator
                formFields={formFields}
                loginMechanisms={['email']}
                hideSignUp={true}
                socialProviders={
                  [
                    /*'google'*/
                  ]
                }
                signUpAttributes={['family_name', 'given_name']}
              />
            ) : (
              <LoadingPage />
            )}
          </ThemeProvider>
        </Box>
      </Flex>
    </Main>
  );
};

export default Index;
